import React from "react";
const Star = () => String.fromCharCode(9733);

const StarRating = ({ rating }) => {
    const calculateFullStarsWidthBasedOnRating = (rating) => {
        if (rating > 5.0 || rating < 0.0) return "0%";

        const percentage = Math.floor((rating / 5.0) * 100.0);
        return percentage + "%";
    };

    return (
        <div style={styles.ratings}>
            <div style={styles.emptyStars}>
                <Star style={{ color: "gray" }} />
                <Star style={{ color: "gray" }} />
                <Star style={{ color: "gray" }} />
                <Star style={{ color: "gray" }} />
                <Star style={{ color: "gray" }} />
            </div>
            <div style={{ ...styles.fullStars, width: calculateFullStarsWidthBasedOnRating(rating) }}>
                <Star style={{ color: "orange" }} />
                <Star style={{ color: "orange" }} />
                <Star style={{ color: "orange" }} />
                <Star style={{ color: "orange" }} />
                <Star style={{ color: "orange" }} />
            </div>
        </div>
    );
};

const styles = {
    ratings: {
        position: "relative",
        verticalAlign: "middle",
        display: "inline-block",
        color: "#b1b1b1",
        overflow: "hidden",
    },
    fullStars: {
        position: "absolute",
        left: "0",
        top: "0",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "orange",
    },
};

export default StarRating;
