import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

export const fetchCities = async () => {
    const snapshot = await getDocs(collection(db, "cities"));
    const citiesSet = new Set();
    snapshot.docs.forEach((doc) => {
        const city = doc.data();
        if (city) {
            citiesSet.add(city);
        }
    });

    return Array.from(citiesSet);
};
