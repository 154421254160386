import { db } from "../firebaseConfig";
import { collection, getDocs, query, orderBy, limit, startAfter, where, or, and } from "firebase/firestore";

export const fetchCourts = async ({ queryKey, pageParam = null }) => {
    const [, { selectedCity, courtIds, priceRanges, additionalContent, courtTypes }] = queryKey;
    const topLevelAndConditions = [];
    const searchAttributes = [];

    if (additionalContent && additionalContent.length > 0) {
        searchAttributes.push(...additionalContent);
    }

    if (courtTypes && courtTypes.length > 0) {
        searchAttributes.push(...courtTypes);
    }

    let queryParams = [orderBy("name", "asc"), limit(10), startAfter(pageParam ?? 0)];
    const courtsRef = collection(db, "courts");

    if (selectedCity) {
        topLevelAndConditions.push(where("city", "==", selectedCity));
    }

    if (courtIds) {
        queryParams.push(where("__name__", "in", courtIds));
    }

    if (priceRanges && priceRanges.length > 0) {
        const orCondition = [];
        priceRanges.map((range) => {
            if (range === "3000+") {
                return orCondition.push(where("price", ">=", 3000));
            } else {
                const [min, max] = range.split("-").map(Number);
                return orCondition.push(and(where("price", ">=", min), where("price", "<", max)));
            }
        });
        topLevelAndConditions.push(and(or(...orCondition)));
    }

    if (searchAttributes && searchAttributes.length > 0) {
        topLevelAndConditions.push(where("search_attr", "array-contains-any", searchAttributes));
    }

    if (topLevelAndConditions.length > 0) {
        if (topLevelAndConditions.length === 1) {
            queryParams.push(topLevelAndConditions[0]);
        } else {
            queryParams.push(and(...topLevelAndConditions));
        }
    }

    let q = query(courtsRef, ...queryParams);
    const snapshot = await getDocs(q);
    const courts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];

    return { courts, nextCursor: lastVisible };
};
