import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const onBookingsChange = (handleBookingsChange) => {
    const unsub = onSnapshot(collection(db, "bookings"), (snapshot) => {
        if (handleBookingsChange) {
            handleBookingsChange();
        }
    });

    return unsub;
};
