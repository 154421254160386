import { db } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";

export const fetchMyCourts = async (userId) => {
    const courtOwnersRef = collection(db, "court_owners");
    const q = query(courtOwnersRef, where("__name__", "==", userId));

    try {
        const snapshot = await getDocs(q);
        const owners = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return owners;
    } catch (error) {
        console.log(error);
    }
};
