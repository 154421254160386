import ErrorIcon from "@mui/icons-material/Error";

const ErrorMessage = ({ errorMessage, handleClose }) => {
    return (
        <div>
            <div style={styles.errorIcon}>
                <ErrorIcon />
            </div>
            <div style={styles.errorTitle}>Došlo je do greške.</div>
            <div style={styles.errorSubtitle}>{errorMessage}</div>
            <div style={styles.errorButtonContainer}>
                <button style={styles.errorButton} onClick={handleClose}>
                    OK
                </button>
            </div>
        </div>
    );
};

const styles = {
    errorIcon: {
        color: "red",
        fontSize: "70px",
        display: "flex",
        justifyContent: "center",
    },
    errorTitle: {
        fontSize: "17px",
        fontWeight: "700",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
    },
    errorSubtitle: {
        fontSize: "11px",
        fontWeight: "700",
        display: "flex",
        justifyContent: "center",
        marginTop: "5px",
        color: "gray",
    },
    errorButtonContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
    },
    errorButton: {
        padding: "8px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "#007BFF",
        color: "#fff",
        textTransform: "uppercase",
        fontWeight: "700",
        width: "50%",
    },
};

export default ErrorMessage;
