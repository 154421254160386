import { db } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getFutureDays, dayToISOFormat } from "../utils/datesUtils";

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const fetchBookings = async (courtId, turfId, state = ["accepted"], startDate = new Date(), endDate = null) => {
    if (!courtId) return [];

    const { queryStartDate, queryEndDate } = formatQueryStartEndIntervals(startDate, endDate);
    const courtIds = getTransforedCourtIds(courtId);

    const bookings = await getBookings(courtIds, turfId, queryStartDate, queryEndDate);
    const cancelledRecurringBookings = bookings.filter((b) => b.wasRecurring);

    const recurringBookings = await getRecurringBookings(
        courtIds,
        turfId,
        queryStartDate,
        queryEndDate,
        cancelledRecurringBookings
    );

    const allBookings = [...bookings, ...recurringBookings];

    return allBookings.filter((t) => state.some((s) => t.reservation_status === s));
};

const getTransforedCourtIds = (courtId) => {
    const courtIds = [];

    // this needs to be fixed!
    if (Array.isArray(courtId)) {
        courtIds.push(...courtId);
    } else {
        courtIds.push(courtId);
    }

    return courtIds;
};

const formatQueryStartEndIntervals = (startDate, endDate) => {
    startDate.setHours(0, 0, 0, 0);
    let queryEndDate = endDate;

    if (!queryEndDate) {
        queryEndDate = new Date(startDate);
        queryEndDate.setDate(queryEndDate.getDate() + 30);
        queryEndDate.setHours(0, 0, 0, 0);
    }

    return { queryStartDate: startDate, queryEndDate: queryEndDate };
};

const getBookings = async (courtIds, turfId, startDate, endDate) => {
    const whereConditions = [
        where("court_id", "in", courtIds),
        where("reservation_status", "in", ["accepted", "requested", "rejected", "cancelled", "split"]),
        where("created_at", ">=", startDate.getTime()),
        where("created_at", "<=", endDate.getTime()),
    ];

    if (turfId) {
        whereConditions.push(where("turf_id", "==", turfId));
    }

    const bookingsRef = collection(db, "bookings");
    const q = query(bookingsRef, ...whereConditions);
    const snapshot = await getDocs(q);
    const bookings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    return bookings;
};

const getCourtsInfo = async (courtIds) => {
    const courtsRef = collection(db, "courts");
    const courtsQuery = query(courtsRef, where("__name__", "in", courtIds));
    const courtsSnap = await getDocs(courtsQuery);
    const courtsInfo = courtsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    return courtsInfo;
};

const getRecurringBookings = async (courtIds, turfId, startDate, endDate, cancelledRecurringBookings) => {
    if (!turfId) return [];

    const { courtId, bookings } = await getRecurringBookingsForTurf(courtIds, turfId);
    const recurringBookingsExpanded = [];

    const cancelledBookingsKeys = cancelledRecurringBookings.map((t) => {
        return `${t.date}-${t.start_hour}:${t.start_minute}-${t.end_hour}:${t.end_minute}`;
    });

    const bookingDays = getBookingIntervalDays(startDate, endDate);

    bookingDays.forEach((wd) => {
        if (wd < startDate) return;

        const weekDay = wd.getDay();
        const weekDayName = dayNames[weekDay];

        bookings?.forEach((rb) => {
            const bookingFound = rb?.days?.indexOf(weekDayName) !== -1;

            const isCancelled =
                cancelledBookingsKeys.indexOf(
                    `${dayToISOFormat(wd)}-${rb.start_hour}:${rb.start_minute}-${rb.end_hour}:${rb.end_minute}`
                ) !== -1;

            if (bookingFound && !isCancelled) {
                recurringBookingsExpanded.push({
                    id: `${rb.id}:${dayToISOFormat(wd)}:${rb.start_hour}-${rb.start_minute}:${rb.end_hour}-${
                        rb.end_minute
                    }`,
                    start_hour: rb.start_hour,
                    start_minute: rb.start_minute,
                    end_hour: rb.end_hour,
                    end_minute: rb.end_minute,
                    reservation_requested_by: rb.reservation_requested_by,
                    court_id: courtId,
                    turf_id: turfId,
                    reservation_status: "accepted",
                    date: dayToISOFormat(wd),
                    isRecurring: true,
                });
            }
        });
    });

    return recurringBookingsExpanded;
};

const getRecurringBookingsForTurf = async (courtIds, turfId) => {
    const courtsInfo = await getCourtsInfo(courtIds);

    const courtInfoForTurf = courtsInfo.find((ci) => ci?.court_turfs.some((ct) => ct.id === turfId));

    const recurringBookingsForTurf = courtInfoForTurf.recurring_bookings?.filter((t) => t.turf_id === turfId);

    return { courtId: courtInfoForTurf.id, bookings: !recurringBookingsForTurf ? [] : recurringBookingsForTurf };
};

const getBookingIntervalDays = (startDate, endDate) => {
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return getFutureDays(startDate, diffDays);
};

export const fetchBookingsByUser = async (email) => {
    const whereConditions = [where("reservation_requested_by", "==", email)];

    const bookingsRef = collection(db, "bookings");
    const q = query(bookingsRef, ...whereConditions);
    const snapshot = await getDocs(q);
    const bookings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    return bookings;
};
