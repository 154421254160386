import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./main.css";

const Register = ({ googleSignIn, signUp, error }) => {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [promotionsChecked, setPromotionsChecked] = useState(true);

    const handleGoogleSignIn = async () => {
        const result = await googleSignIn();
        if (result?.success) {
            navigate("/");
        }
    };

    const handleSignUp = async (data) => {
        const result = await signUp(data.firstName, data.lastName, data.email, data.password, data.phoneNumber);
        if (result?.success) {
            navigate("/");
        }
    };

    return (
        <div className="mainContainer">
            <div className="logoImage"></div>
            <div className="registerSection">
                <div className="registerFormSection">
                    <div className="registrationHeader">
                        <div className="auth-header">
                            <h2>Kreiraj nalog!</h2>
                        </div>
                        <div className="headerInfo">
                            <span>
                                Već imaš nalog? <Link to="/login">Uloguj se!</Link>
                            </span>
                        </div>
                    </div>

                    <form className="registerForm" onSubmit={handleSubmit((data) => handleSignUp(data))}>
                        <div className="formGroup">
                            <label className="formLabel">IME</label>
                            <input
                                className="formTextInput"
                                type="text"
                                placeholder="tvoje ime"
                                {...register("firstName", {
                                    required: "Ime je obavezno polje",
                                    maxLength: {
                                        value: 20,
                                        message: "Ime ne može biti duže od 20 karaktera",
                                    },
                                })}
                            />
                            {errors.firstName && (
                                <span className="registerForm-errorMessage">{errors.firstName?.message}</span>
                            )}
                        </div>
                        <div className="formGroup">
                            <label className="formLabel">PREZIME</label>
                            <input
                                className="formTextInput"
                                type="text"
                                placeholder="tvoje prezime"
                                {...register("lastName", {
                                    required: "Prezime je obavezno polje",
                                    maxLength: {
                                        value: 20,
                                        message: "Prezime ne može biti duže od 20 karaktera",
                                    },
                                })}
                            />
                            {errors.lastName && (
                                <span className="registerForm-errorMessage">{errors.lastName?.message}</span>
                            )}
                        </div>
                        <div className="formGroup">
                            <label className="formLabel">BROJ TELEFONA</label>
                            <input
                                className="formTextInput"
                                type="text"
                                placeholder="tvoj broj telefona"
                                {...register("phoneNumber", {
                                    required: "Broj telefona je obavezno polje",
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "Molimo, unesite samo numeričke karaktere",
                                    },
                                    maxLength: {
                                        value: 15,
                                        message: "Broj telefona ne može biti duži od 15 karaktera",
                                    },
                                })}
                            />
                            {errors.phoneNumber && (
                                <span className="registerForm-errorMessage">{errors.phoneNumber?.message}</span>
                            )}
                        </div>
                        <div className="formGroup">
                            <label className="formLabel">EMAIL</label>
                            <input
                                className="formTextInput"
                                type="text"
                                placeholder="tvoja email adresa"
                                {...register("email", {
                                    required: "Email je obavezno polje",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Molimo, unesite validan email",
                                    },
                                })}
                            />
                            {errors.email && <span className="registerForm-errorMessage">{errors.email?.message}</span>}
                        </div>
                        <div className="formGroup">
                            <label className="formLabel">PASSWORD</label>
                            <input
                                className="formTextInput"
                                type="password"
                                {...register("password", {
                                    required: "Lozinka je obavezno polje",
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,20}$/,
                                        message: "Lozinka mora sadržati slova, brojeve i specijalne karaktere",
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: "Lozinka ne može biti duža od 20 karaktera",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "Lozinka mora biti duža od 8 karaktera",
                                    },
                                })}
                            />
                            {errors.password && (
                                <span className="registerForm-errorMessage">{errors.password?.message}</span>
                            )}
                        </div>
                        <div className="formGroup">
                            <span className="promotionSpan">
                                <input
                                    type="checkbox"
                                    checked={promotionsChecked}
                                    onChange={() => setPromotionsChecked(!promotionsChecked)}
                                />{" "}
                                Prijavi se na naš newsletter i saznaj sve o novim promocijama
                            </span>
                        </div>
                        <div className="formGroup">
                            <button type="submit" className="btnPrimary">
                                KREIRAJ NALOG
                            </button>
                        </div>

                        {error && <p className="errorMessage">{error}</p>}

                        <div className="googleAuth">
                            <label className="googleAuthLabel">Ili se registruj putem</label>
                            <button type="button" className="googleAuthButton" onClick={handleGoogleSignIn}>
                                <div className="gsiMaterialButtonIcon">
                                    <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 48 48"
                                        style={{ display: "block" }}
                                    >
                                        <path
                                            fill="#EA4335"
                                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                        ></path>
                                        <path
                                            fill="#4285F4"
                                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                        ></path>
                                        <path
                                            fill="#FBBC05"
                                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                        ></path>
                                        <path
                                            fill="#34A853"
                                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                        ></path>
                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                    </svg>
                                </div>
                                <span>Prijavi se preko Google-a</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
