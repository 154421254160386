import "./main.css";
import HomeIcon from "@mui/icons-material/Home";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useNavigate } from "react-router-dom";
import BookingModal from "../BookingModal/BookingModal";
import { useBookingModal } from "../../hooks/useBookingModal";
import { useBooking } from "../../hooks/useBooking";

const BookBanner = ({ user, courtId, turfId }) => {
    const navigate = useNavigate();
    const { isModalOpen, openBookingModal, closeBookingModal } = useBookingModal();
    const { addBooking } = useBooking({ user, courtId, turfId });

    const handleNavigateHome = () => navigate("/");
    const handleNavigateLeague = () => navigate("/league");
    const handleOpenBookModal = () => openBookingModal();

    const handleBookingSubmitted = async (bookingDetails) => {
        return await addBooking(bookingDetails.date, bookingDetails.startTime, bookingDetails.endTime);
    };

    return (
        <div className="bookBanner-container">
            <BookingModal isOpen={isModalOpen} onClose={closeBookingModal} onSubmit={handleBookingSubmitted} />
            <div className="bookBanner-homeBtnContainer">
                <div className="bookBanner-transparent"></div>
                <div className="bookBanner-button" onClick={handleNavigateHome}>
                    <HomeIcon className="bookBanner-icon" />
                    <span>Početna</span>
                </div>
            </div>
            <div className="bookBanner-bookBtn">
                <button onClick={handleOpenBookModal}>Rezerviši</button>
            </div>
            <div className="bookBanner-leagueBtn">
                <div className="bookBanner-transparent"></div>
                <div className="bookBanner-button" onClick={handleNavigateLeague}>
                    <EmojiEventsIcon className="bookBanner-icon" />
                    <span>Liga</span>
                </div>
            </div>
        </div>
    );
};

export default BookBanner;
