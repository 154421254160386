import { useEffect, useState, useCallback } from "react";
import { fetchMyCourts } from "../../api/fetchMyCourts";
import { useNavigate } from "react-router-dom";
import { fetchBookings } from "../../api/fetchBookings";
import { onBookingsChange } from "../../api/onBookingsChange";
import useCourtInfo from "../../store/useCourtInfo";

const MyCourtsBanner = ({ user, darkMode = false }) => {
    const [myCourts, setMyCourts] = useState([]);
    const [myRequestedBookings, setMyRequestedBookings] = useState([]);
    const navigate = useNavigate();
    const setNumberOfBookingsPerTurf = useCourtInfo((state) => state.setNumberOfBookingsPerTurf);

    useEffect(() => {
        const fetchCourts = async () => {
            if (user) {
                const courts = await fetchMyCourts(user.uid);
                setMyCourts(courts);
            }
        };

        fetchCourts();
    }, [user]);

    const calculateNumberOfBookingsPerTurf = useCallback(
        (bookings) => {
            if (bookings) {
                const numberOfBookingsPerTurf = {};

                bookings.forEach((b) => {
                    if (b.turf_id in numberOfBookingsPerTurf) {
                        numberOfBookingsPerTurf[b.turf_id] = numberOfBookingsPerTurf[b.turf_id] + 1;
                    } else {
                        numberOfBookingsPerTurf[b.turf_id] = 1;
                    }
                });

                setNumberOfBookingsPerTurf(numberOfBookingsPerTurf);
            }
        },
        [setNumberOfBookingsPerTurf]
    );

    const fetchBookingsForCourt = useCallback(async () => {
        if (myCourts && myCourts.length > 0) {
            const courtIds = myCourts[0].court_ids;
            const bookings = await fetchBookings(courtIds, null, ["requested"]);
            setMyRequestedBookings(bookings);
            calculateNumberOfBookingsPerTurf(bookings);
        }
    }, [myCourts, calculateNumberOfBookingsPerTurf]);

    useEffect(() => {
        fetchBookingsForCourt();
    }, [myCourts, fetchBookingsForCourt]);

    const handleBookingsChanged = useCallback(() => {
        fetchBookingsForCourt();
    }, [fetchBookingsForCourt]);

    useEffect(() => {
        const cleanup = onBookingsChange(handleBookingsChanged);

        return () => cleanup();
    }, [handleBookingsChanged]);

    const handleClick = () => navigate("/mycourts");

    return (
        <>
            {user && myCourts && myCourts.length > 0 && (
                <div className="mycourts-bookings-container">
                    <div className={darkMode ? "myCourtsButton-dark" : "myCourtsButton"} onClick={handleClick}>
                        Rezervacije
                    </div>
                    {myRequestedBookings && myRequestedBookings.length > 0 && (
                        <div className="mycourts-bookings-notifications">{myRequestedBookings.length}</div>
                    )}
                </div>
            )}
        </>
    );
};

export default MyCourtsBanner;
