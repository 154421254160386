import { useParams } from "react-router-dom";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import { useEffect, useState } from "react";
import { fetchCourt } from "../../api/fetchCourt";
import StarRating from "../common/StarRating";
import PlaceIcon from "@mui/icons-material/Place";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import "../../App.css";
import Navbar from "../Navbar/Navbar";
import "./main.css";
import BookingManager from "../BookingManager/BookingManager";
import BookBanner from "../BookBanner/BookBanner";
import CourtTags from "./CourtTags";
import BookingModal from "../BookingModal/BookingModal";
import { useBookingModal } from "../../hooks/useBookingModal";
import { useBooking } from "../../hooks/useBooking";

const Court = ({ user, signOut }) => {
    const { courtId } = useParams();
    const [courtDetails, setCourtDetails] = useState(null);
    const [selectedTurf, setSelectedTurf] = useState(null);
    const [turfTags, setTurfTags] = useState([]);
    const { isModalOpen, openBookingModal, closeBookingModal } = useBookingModal();
    const { addBooking } = useBooking({ user, courtId, turfId: selectedTurf });

    useEffect(() => {
        const fetchCourtsDetails = async () => {
            if (user) {
                const { court } = await fetchCourt(courtId);
                setCourtDetails(court);
                if (court.court_turfs && court.court_turfs.length > 0) {
                    setSelectedTurf(court.court_turfs[0]?.id);
                    setTurfTags(court.court_turfs[0]?.tags);
                }
            }
        };

        fetchCourtsDetails();
    }, [user, courtId]);

    const handleTurfChange = (e) => {
        const turfId = e.target.getAttribute("data-index");
        setSelectedTurf(turfId);
        const tags = courtDetails?.court_turfs?.find((t) => t.id === turfId)?.tags;

        if (tags && tags?.length > 0) {
            setTurfTags(tags);
        } else {
            setTurfTags([]);
        }
    };

    const handleBookingSubmit = async (bookingDetails) => {
        return await addBooking(bookingDetails.date, bookingDetails.startTime, bookingDetails.endTime);
    };

    const handleBookButton = () => {
        openBookingModal();
    };

    const getBackgroundColorPerTurfType = (turf) => {
        if (turf?.name?.includes("Padel")) return "#2191d0";
        if (turf?.tags?.includes("hardcourt")) return "blue";

        return "#F04A4C";
    };

    return (
        <div className="court-contentContainer">
            <div className="court-scrollableContent">
                <div className="court-topSection">
                    <Navbar user={user} signOut={signOut} darkLogo={true} border={true} />
                    <div className="image-carousel">{<ImageCarousel images={courtDetails?.court_images ?? []} />}</div>
                    <div className="courtInfoContainer">
                        <div className="courtInformation">
                            <div className="court-courtName">{courtDetails?.name}</div>
                            <div className="courtContent">Karakteristike Terena</div>
                        </div>
                        <div className="court-courtAdditionalInformation">
                            <div className="courtRatingAndAddressContainer">
                                <div className="courtRating">
                                    <StarRating rating={courtDetails?.rating} />
                                    <div className="ratingNumber">{courtDetails?.rating}</div>
                                </div>
                                <div className="courtAddress">
                                    <PlaceIcon className="placeIcon" />
                                    {courtDetails?.address}
                                </div>
                            </div>
                            <div className="courtAdditionalContent">
                                <div className="contentList">
                                    {courtDetails?.tags?.includes("coffee") && (
                                        <div className="contentItem">
                                            <FreeBreakfastIcon className="contentIcon" />
                                            <div className="contentText">Kafić/Restoran</div>
                                        </div>
                                    )}
                                    {courtDetails?.tags?.includes("parking") && (
                                        <div className="contentItem">
                                            <LocalParkingIcon className="contentIcon" />
                                            <div className="contentText">Parking</div>
                                        </div>
                                    )}
                                    {courtDetails?.tags?.includes("lockerroom") && (
                                        <div className="contentItem">
                                            <CheckroomIcon className="contentIcon" />
                                            <div className="contentText">Svlačionice</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="availableTurfs">Raspoloživi tereni</div>
                    <div className="courtTurf">
                        {courtDetails?.court_turfs.map((turf, pageIndex) => (
                            <div
                                style={{
                                    backgroundColor: getBackgroundColorPerTurfType(turf),
                                    opacity: turf?.id === selectedTurf ? "1" : "0.3",
                                }}
                                className="turf"
                                data-index={turf?.id}
                                key={turf?.id}
                                onClick={handleTurfChange}
                            >
                                {turf?.name}
                            </div>
                        ))}
                    </div>
                    <div className="courtTags-mobile">
                        <CourtTags turfTags={turfTags} />
                    </div>
                    <div className="bookingInfoContainer">
                        <div className="calendarInfo">
                            <BookingManager
                                user={user}
                                courtId={courtDetails?.id}
                                turfId={selectedTurf}
                                price={courtDetails?.price}
                                turfTags={turfTags}
                            />
                        </div>
                    </div>
                    <div className="court-bookingInfo">
                        <div className="court-price">{courtDetails?.price} RSD</div>
                        <div>
                            <button className="court-bookButton" onClick={handleBookButton}>
                                Rezerviši
                            </button>
                        </div>
                    </div>
                    <div className="announcements">
                        <div className="announcementTitle">Objave terena</div>
                        <div className="announcementsContent">
                            <div className="announcementItem">Trenutno nema objava za ovaj teren</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="court-bannerContainer">
                <BookBanner user={user} courtId={courtDetails?.id} turfId={selectedTurf} />
            </div>
            <BookingModal
                courtId={courtId}
                turfId={selectedTurf}
                isOpen={isModalOpen}
                onClose={closeBookingModal}
                onSubmit={handleBookingSubmit}
            />
        </div>
    );
};

export default Court;
