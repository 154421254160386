const FilterGroup = ({ title, content, checkedItems, handleChecked }) => {
    const handleChange = (itemId) => {
        handleChecked(itemId);
    };

    return (
        <div style={styles.filterGroup}>
            <h4 style={styles.filterHeading}>{title}</h4>
            {content.map((item) => (
                <div key={item.id} style={styles.filter}>
                    <div>
                        <input
                            style={styles.input}
                            type="checkbox"
                            value={item.value}
                            id={item.id}
                            checked={checkedItems?.some(item.id)}
                            onChange={() => handleChange(item.id)}
                        />
                    </div>
                    <div>
                        <label>{item.value}</label>
                    </div>
                </div>
            ))}
        </div>
    );
};

const styles = {
    container: {
        marginBottom: "16px",
    },
    filters: {},
    filterHeading: {
        color: "black",
        textAlign: "left",
    },
    filterGroup: {
        paddingBottom: "25px",
        color: "gray",
        fontSize: "14px",
        borderBottom: "1px solid lightgray",
        textAlign: "left",
    },
    input: {
        marginTop: "2px",
    },
    filter: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        marginBottom: "8px",
    },
};

export default FilterGroup;
