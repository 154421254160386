import { db } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";

export const fetchCourt = async (courtId) => {
    const courtsRef = collection(db, "courts");

    const queryParams = [where("__name__", "==", courtId)];

    let q = query(courtsRef, ...queryParams);
    const snapshot = await getDocs(q);

    const courts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    const court = courts?.length > 0 ? courts[0] : {};

    return { court };
};
