import React, { useState, useCallback, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { fetchCourts } from "../../api/fetchCourts";
import CourtCard from "../CourtCard/CourtCard";
import Filters from "./Filters";
import ServersideFilter from "../common/ServersideFilter";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import StadiumIcon from "@mui/icons-material/Stadium";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./main.css";
import Navbar from "../Navbar/Navbar";
import { fetchCities } from "../../api/fetchCities";
import { fetchCourtNames } from "../../api/fetchCourtNames";

const PagedCourts = ({ user, signOut }) => {
    const [filters, setFilters] = useState({});
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCourt, setSelectedCourt] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useInfiniteQuery(
        ["courts", { ...filters, selectedCity, selectedCourt }],
        fetchCourts,
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor || undefined,
        }
    );

    const filterAttributes = useMemo(() => {
        return { city: selectedCity };
    }, [selectedCity]);

    const handleApplyFilters = useCallback((appliedFilters) => {
        setFilters(appliedFilters);
    }, []);

    const handleCitySelect = (city) => {
        setSelectedCity(city);
    };

    const handleCourtSelect = (court) => {
        setSelectedCourt(court);
    };

    const handleShowFilters = () => setShowFilters((prev) => !prev);

    return (
        <div className="courts-container">
            <div className="topSection">
                <Navbar user={user} signOut={signOut} />
                <div className="heroSectionTitle">
                    <div>Naša mreža terena</div>
                    <div>čeka na vas</div>
                </div>
                <div className="heroSectionSubtitle">
                    Uđite u svet terena koji odišu energijom i strašću. Bilo da ste rekreativac ili profesionalac, naši
                    tereni su tu da podrže vaše strasti i omoguće vam da napredujete u igri.
                </div>
            </div>
            <div className="selectionBox">
                <div className="selectionContent">
                    <div className="selectionTitle">
                        <BookmarkBorderIcon className="bookmarkIcon" />
                        <div>Rezervišite svoj termin sada!</div>
                    </div>
                    <div className="selectorsBox">
                        <div className="selectors">
                            <div className="dropdownBox">
                                <ServersideFilter
                                    icon={LocationCityIcon}
                                    placeholderText={"Izaberite grad"}
                                    className="dropdown"
                                    onOptionSelected={handleCitySelect}
                                    fetchFuction={fetchCities}
                                />
                            </div>
                            <div className="dropdownBox">
                                <ServersideFilter
                                    icon={StadiumIcon}
                                    placeholderText={"Izaberite teren"}
                                    className="dropdown"
                                    onOptionSelected={handleCourtSelect}
                                    disabled={!selectedCity}
                                    fetchFuction={fetchCourtNames}
                                    externalDependencies={filterAttributes}
                                />
                            </div>
                            <div className="dropdownBox">
                                <ServersideFilter
                                    icon={AccessTimeIcon}
                                    placeholderText={"Izaberite vreme"}
                                    className="dropdown"
                                    onOptionSelected={handleCitySelect}
                                    disabled={true}
                                />
                            </div>
                            <div className="searchButton">
                                <button className="registerButton">Pretražite</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="heading">
                <h2>Tereni</h2>
                <button className="btnPrimary courts-filtersBtn" onClick={handleShowFilters}>
                    Filteri
                </button>
            </div>
            <div className="content">
                <div className={showFilters ? "filters" : "filters hideFiltersOnMobile"}>
                    <Filters onApplyFilters={handleApplyFilters} />
                </div>
                <div className="courts">
                    {status === "loading" && <p>Učitavam terene...</p>}
                    {status === "error" && <p>Greška pri učitavanju terena</p>}
                    {status === "success" && (
                        <div>
                            {data.pages.map((page, pageIndex) => (
                                <React.Fragment key={pageIndex}>
                                    {page.courts.map((court) => (
                                        <CourtCard key={court.id} court={court} user={user} />
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                    <div>
                        {isFetchingNextPage ? (
                            <p>Učitavam terene...</p>
                        ) : (
                            hasNextPage && (
                                <button className="pagedCourts-moreCourtsBtn" onClick={() => fetchNextPage()}>
                                    Učitaj još terena
                                </button>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PagedCourts;
