export const formatDate = (date) => {
    return date.toLocaleDateString("sr-Latn-RS", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
};

export const getStartOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust for week starting on Monday
    return new Date(date.setDate(diff));
};

export const getFutureDays = (startDate, numberOfFutureDays) => {
    const days = [];
    for (let i = 0; i < numberOfFutureDays; i++) {
        const day = new Date(startDate);
        day.setDate(day.getDate() + i);
        days.push(day);
    }
    return days;
};

export const getWeekDays = (startDate) => getFutureDays(startDate, 7);

export const formatTime = (startHour, startMinute, endHour, endMinute) => {
    return `${formatTimePart(startHour)}:${formatTimePart(startMinute)} - ${formatTimePart(endHour)}:${formatTimePart(
        endMinute
    )}`;
};

export const formatTimePart = (timePart) =>
    timePart >= 0 && timePart < 10 ? `0${timePart.toString()}` : timePart.toString();

export const dayToISOFormat = (day) => {
    const offset = day.getTimezoneOffset();
    day = new Date(day.getTime() - offset * 60 * 1000);
    return day.toISOString().split("T")[0];
};

export const getNextSevenDays = (startDate = new Date()) => {
    const days = [];
    const today = startDate;

    for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        days.push({
            date: dayToISOFormat(date),
            dayName: date.toLocaleDateString("en-US", { weekday: "long" }), // Day of the week
        });
    }

    return days;
};
