import { useState, useEffect } from "react";
import { fetchMyCourts } from "../../api/fetchMyCourts";
import { fetchCourts } from "../../api/fetchCourts";
import MyCourt from "./MyCourt";
import Navbar from "../Navbar/Navbar";
import "./main.css";

const MyCourts = ({ user, signOut }) => {
    const [courtDetails, setCourtDetails] = useState([]);

    useEffect(() => {
        const fetchCourtsDetails = async () => {
            if (user) {
                const myCourts = await fetchMyCourts(user.uid);
                const courtIds = myCourts && myCourts.length > 0 ? myCourts[0]?.court_ids : [];
                const details = await fetchCourts({ queryKey: ["courts", { courtIds: courtIds }] });
                setCourtDetails(details.courts);
            }
        };

        fetchCourtsDetails();
    }, [user]);

    return (
        <div>
            <div className="mycourts-contentContainer">
                <Navbar user={user} signOut={signOut} darkLogo={true} border={true} />
                <div className="mycourts-reservationTitleBox">
                    <div className="mycourts-reservationTitle">Pregled Rezervacija</div>
                    <div className="mycourts-reservationImage">
                        {courtDetails && courtDetails.length > 0 && (
                            <img className="mycourts-img" src={courtDetails[0].image_url} alt="court_image" />
                        )}
                    </div>
                </div>
                {
                    <div className="mycourts-courtList">
                        {courtDetails &&
                            courtDetails.map((court) => <MyCourt key={court?.id} court={court} user={user} />)}
                    </div>
                }
            </div>
        </div>
    );
};

export default MyCourts;
