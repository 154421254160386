export const additionalContent = [
    {
        id: "lockerroom",
        value: "Tuševi i svlačionice",
    },
    {
        id: "coffee",
        value: "Kafić/restoran",
    },
    {
        id: "parking",
        value: "Parking",
    },
];
export const courtTypes = [
    {
        id: "hardcourt",
        value: "Beton",
    },
    {
        id: "clay",
        value: "Šljaka",
    },
    {
        id: "padel",
        value: "Padel",
    },
];

export const priceRanges = [
    {
        id: "0-1000",
        value: "0-1000",
    },
    {
        id: "1000-2000",
        value: "1000-2000",
    },
    {
        id: "2000-3000",
        value: "2000-3000",
    },
    {
        id: "3000+",
        value: "3000+",
    },
];

export const allBookings = ["accepted", "rejected", "requested"];
export const bookingSuccessMessage = "Potvrda o rezervaciji će biti poslata na vaš email kada je teren odobri";
export const adminBookingSuccessMessage = "Termin je uspešno dodat.";
