import { db } from "../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const hasIntersection = (array1, array2) => {
    const intersection = [array1, array2].reduce((a, c) => a.filter((i) => c.includes(i)));
    return intersection && intersection.length > 0;
};

const isRecurringBookingValid = (bookingToAdd, currentBookings) => {
    if (!currentBookings || currentBookings.length === 0) return true;

    const recurringDaysToAdd = bookingToAdd?.recurringDays?.map((rd) => rd.day);
    const bookingsOnTheSameDays = currentBookings.filter((cb) => hasIntersection(cb?.days, recurringDaysToAdd));

    if (!bookingsOnTheSameDays || bookingsOnTheSameDays.length === 0) return true;

    const overlappingBookings = bookingsOnTheSameDays.filter((b) => {
        if (b.start_hour >= bookingToAdd.endTime.hour || b.end_hour <= bookingToAdd.startTime.hour) return false;

        return true;
    });

    return !overlappingBookings || overlappingBookings.length === 0;
};

const isRecurringBookingOverlappingWithStandardBookings = (bookingToAdd, standardBookings) => {
    if (!standardBookings || standardBookings.length === 0) return false;

    const requestedBookings = standardBookings.filter((t) => t.reservation_status === "accepted");

    const overlappingDays = requestedBookings.filter((t) => {
        const bookingDayName = days[new Date(t.date).getDay()];
        const recurringBookingDays = bookingToAdd?.recurringDays?.map((t) => t.day);
        return recurringBookingDays?.some((t) => t === bookingDayName);
    });

    if (!overlappingDays || overlappingDays.length === 0) return false;

    const overlappingHours = overlappingDays.filter((od) => {
        if (od.start_hour >= bookingToAdd.endTime.hour || od.end_hour <= bookingToAdd.startTime.hour) return false;

        return true;
    });

    if (!overlappingHours || overlappingHours.length === 0) return false;

    return true;
};

export const addRecurringBooking = async (court, selectedTurf, bookingDetails, standardBookings = null) => {
    try {
        const courtRef = doc(db, "courts", court.id);

        if (
            !isRecurringBookingValid(
                bookingDetails,
                court.recurring_bookings?.filter((t) => t.turf_id === selectedTurf)
            )
        )
            return {
                success: false,
                errorMessage: "Termin koji pokušavate da unesete se preklapa sa već postojećim terminima!",
            };

        if (isRecurringBookingOverlappingWithStandardBookings(bookingDetails, standardBookings)) {
            return {
                success: false,
                errorMessage: "Termin koji pokušavate da unesete se preklapa sa već postojećim terminima!",
            };
        }

        const updatedRecurringBookings = !court.recurring_bookings ? [] : court.recurring_bookings;

        updatedRecurringBookings.push({
            id: uuidv4(),
            turf_id: selectedTurf,
            start_hour: bookingDetails.startTime.hour,
            start_minute: bookingDetails.startTime.minute,
            end_hour: bookingDetails.endTime.hour,
            end_minute: bookingDetails.endTime.minute,
            reservation_requested_by: bookingDetails.customUser,
            days: bookingDetails.recurringDays.map((t) => t.day),
        });

        await updateDoc(courtRef, {
            recurring_bookings: updatedRecurringBookings,
        });
    } catch (err) {
        return { success: false, errorMessage: "Serverska greška!" };
    }

    return { success: true };
};

export const removeRecurringBooking = async (court, selectedTurf, bookingId) => {
    const courtRef = doc(db, "courts", court.id);
    const currentRecurringBookings = court.recurring_bookings;

    const newRecurringBookings = currentRecurringBookings?.filter((t) => t.id !== bookingId);

    await updateDoc(courtRef, {
        recurring_bookings: newRecurringBookings,
    });
};
