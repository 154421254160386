import React from "react";
import { useNavigate } from "react-router-dom";
import StarRateIcon from "@mui/icons-material/StarRate";
import PlaceIcon from "@mui/icons-material/Place";
import "./main.css";

const validTagsMap = {
    parking: "Parking",
    coffee: "Kafić/Restoran",
    lockerroom: "Svlačionice",
};

const CourtCard = ({ court, user }) => {
    const navigate = useNavigate();

    const handleBookClick = () => {
        if (user) {
            navigate(`/courts/${court.id}`);
        } else {
            navigate(`/login`);
        }
    };

    return (
        <div className="card-container">
            <div className="card-imageContainer">
                <img src={court.image_url} alt={court.name} className="card-image" onClick={handleBookClick} />
            </div>
            <div className="details">
                <div className="courtName">
                    <div>{court.name}</div>
                </div>
                <div className="courtInfo">
                    <div className="rating">
                        <StarRateIcon className="starIcon" />
                        <div className="ratingText">{court.rating}</div>
                    </div>
                    <div className="address">
                        <PlaceIcon />
                        <div className="addressText">{court.address}</div>
                    </div>
                </div>
                <div className="tags">
                    {court.tags.map((tag, pageIndex) => (
                        <div className="tag" key={pageIndex}>
                            {validTagsMap.hasOwnProperty(tag) && validTagsMap[tag]}
                        </div>
                    ))}
                </div>
                <div className="card-bookingInfo">
                    <div className="card-price">{court.price} RSD</div>
                    <div className="card-bookButton">
                        <button onClick={handleBookClick}>Zakaži</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourtCard;
