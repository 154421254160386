import { useState } from "react";

export const useBookingModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openBookingModal = () => {
        setIsModalOpen(true);
    };

    const closeBookingModal = () => {
        setIsModalOpen(false);
    };

    return {
        isModalOpen,
        openBookingModal,
        closeBookingModal,
    };
};
