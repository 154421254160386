import { db } from "../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";

export const fetchUserInfo = async (userId) => {
    const usersRef = doc(db, "users", userId);

    try {
        const snapshot = await getDoc(usersRef);
        if (snapshot.exists()) return snapshot.data();

        return null;
    } catch (error) {
        console.log(error);
    }
};
