import MyCourtsBanner from "../MyCourts/MyCourtsBanner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import "./main.css";
import { useState } from "react";

const Navbar = ({ user, signOut, darkLogo = false, border = false }) => {
    const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

    const navigate = useNavigate();
    const handleLogoClick = () => navigate("/");
    const handleProfileClick = () => navigate("/myprofile");

    return (
        <>
            <div className="navbar">
                <div className={darkLogo ? "darklogo" : "logo"} onClick={handleLogoClick}>
                    CourtHub
                </div>
                {user ? (
                    <>
                        <div className="loggedInUserNavbar">
                            {user && <MyCourtsBanner user={user} darkMode={darkLogo} />}
                            {user && (
                                <div
                                    className="navbar-myprofile-link"
                                    style={{
                                        color: darkLogo ? "black" : "white",
                                        fontWeight: darkLogo ? "bold" : "normal",
                                    }}
                                    onClick={handleProfileClick}
                                >
                                    Moj Profil
                                </div>
                            )}
                            <div className="logoutContainer">
                                <button className="registerButton" onClick={signOut}>
                                    Izlogujte se
                                </button>
                            </div>
                        </div>
                        <div className="navbar-hamburgerMenu">
                            <div className="navbar-hamburgerMenu-icon">
                                <Hamburger
                                    color={darkLogo ? "black" : "white"}
                                    toggled={isHamburgerMenuOpen}
                                    toggle={setHamburgerMenuOpen}
                                />
                            </div>
                            {isHamburgerMenuOpen && (
                                <div className="navbar-hamburgerMenu-options">
                                    {user && <MyCourtsBanner user={user} darkMode={true} />}
                                    {user && (
                                        <div
                                            style={{
                                                color: "black",
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                            }}
                                            onClick={handleProfileClick}
                                        >
                                            Moj Profil
                                        </div>
                                    )}
                                    <div>
                                        <button className="registerButton" onClick={signOut}>
                                            Izlogujte se
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="authButtons">
                        <button className="loginButton">
                            <Link className="loginLink" to="/login">
                                Ulogujte se
                            </Link>
                        </button>
                        <button className="registerButton">
                            <Link className="registerLink" to="/register">
                                Registrujte se
                            </Link>
                        </button>
                    </div>
                )}
            </div>
            {border ? <div className="navbarBorder"></div> : null}
        </>
    );
};

export default Navbar;
