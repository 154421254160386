const CourtTags = ({ turfTags }) => {
    const validTags = ["clay", "cover", "hardcourt"];

    const validTagsMap = {
        clay: "Šljaka",
        cover: "Pokriven",
        hardcourt: "Beton",
    };

    return (
        <div className="courtTags">
            {validTags?.map((tag, pageIndex) => (
                <div
                    className="court-tag"
                    key={pageIndex}
                    style={{
                        backgroundColor: turfTags?.some((t) => t === tag) ? "#7BB542" : "lightgray",
                        color: turfTags?.some((t) => t === tag) ? "#fff" : "black",
                    }}
                >
                    {validTagsMap.hasOwnProperty(tag) && validTagsMap[tag]}
                </div>
            ))}
        </div>
    );
};

export default CourtTags;
