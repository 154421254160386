import { useState, useCallback, useEffect } from "react";
import { fetchBookings } from "../../api/fetchBookings";
import StarRating from "../common/StarRating";
import { formatDate } from "../../utils/datesUtils";
import { useBooking } from "../../hooks/useBooking";
import { allBookings } from "../../utils/constants";
import BookingModal from "../BookingModal/BookingModal";
import { useBookingModal } from "../../hooks/useBookingModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { onBookingsChange } from "../../api/onBookingsChange";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { addRecurringBooking, removeRecurringBooking } from "../../api/recurringBookings";
import useCourtInfo from "../../store/useCourtInfo";
import ConfirmationModal from "../common/ConfirmationModal/ConfirmationModal";

const MyCourt = ({ court, user }) => {
    const [selectedTurf, setSelectedTurf] = useState(court?.court_turfs?.length > 0 ? court?.court_turfs[0].id : null);
    const [bookings, setBookings] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showRecurringBookings, setShowRecurringBookings] = useState(false);
    const { isModalOpen, openBookingModal, closeBookingModal } = useBookingModal();
    const numberOfBookingsPerTurf = useCourtInfo((state) => state.numberOfBookingsPerTurf);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);
    const [bookingIdToReject, setBookingIdToReject] = useState(null);
    const [isSplitModalOpen, setSplitModalOpen] = useState(false);
    const [bookingIdToSplit, setBookingIdToSplit] = useState(null);

    const { addBooking, acceptBooking, rejectBooking, splitBooking } = useBooking({
        user,
        courtId: court?.id,
        turfId: selectedTurf,
        bookingStates: allBookings,
    });

    const recurringBookings = court?.recurring_bookings?.filter((t) => t.turf_id === selectedTurf) ?? [];

    const handleTurfChange = (e) => {
        const turfId = e.target.getAttribute("data-turf");
        setSelectedTurf(turfId);
    };

    const fetchBookingsForCourt = useCallback(async () => {
        if (court?.id && selectedTurf) {
            const bookingsForCourt = await fetchBookings(court?.id, selectedTurf, [
                "requested",
                "accepted",
                "rejected",
                "cancelled",
            ]);

            setBookings(bookingsForCourt);
        }
    }, [court?.id, selectedTurf]);

    useEffect(() => {
        fetchBookingsForCourt();
    }, [court?.id, selectedTurf, fetchBookingsForCourt]);

    const handleBookingsChanged = useCallback(() => {
        fetchBookingsForCourt();
    }, [fetchBookingsForCourt]);

    useEffect(() => {
        const cleanup = onBookingsChange(handleBookingsChanged);

        return () => cleanup();
    }, [handleBookingsChanged]);

    const formatTime = (startHour, startMinute, endHour, endMinute) => {
        return `${formatTimePart(startHour)}:${formatTimePart(startMinute)} - ${formatTimePart(
            endHour
        )}:${formatTimePart(endMinute)}`;
    };

    const formatTimePart = (timePart) => (timePart === 0 ? "00" : timePart.toString());

    const getReservationStatusOrder = (reservationStatus) => {
        switch (reservationStatus) {
            case "requested":
                return 1;
            case "accepted":
                return 2;
            case "rejected":
                return 3;
            default:
                return 4;
        }
    };

    const formatBookingsByDay = () => {
        const byDay = {};
        const distinctDates = [];

        bookings?.sort(
            (a, b) =>
                getReservationStatusOrder(a?.reservation_status) - getReservationStatusOrder(b?.reservation_status) ||
                a?.start_hour - b?.start_hour
        );

        bookings?.map((b) => {
            const date = new Date(b.date);

            if (!(date in byDay)) {
                byDay[date] = [];
                distinctDates.push(date);
            }
            byDay[date].push({
                id: b.id,
                date: b.date,
                courtId: b.court_id,
                turfId: b.turf_id,
                startHour: b.start_hour,
                startMinute: b.start_minute,
                endHour: b.end_hour,
                endMinute: b.end_minute,
                reservationRequestedBy: b.reservation_requested_by,
                reservationStatus: b.reservation_status,
                isRecurring: b.isRecurring,
            });

            return byDay;
        });

        distinctDates.sort(function (a, b) {
            return a.getTime() - b.getTime();
        });

        return {
            allDates: distinctDates,
            bookingsByDay: byDay,
        };
    };

    const formattedBookingsByDay = formatBookingsByDay();

    const getReservationStatusText = (reservationStatusCode) => {
        switch (reservationStatusCode) {
            case "requested":
                return "Na čekanju";
            case "accepted":
                return "Odobren";
            case "rejected":
                return "Odbijen";
            case "cancelled":
                return "Korisnik otkazao";
            default:
                return "Nepoznato";
        }
    };

    const getReservationStatusClass = (reservationStatusCode) => {
        switch (reservationStatusCode) {
            case "requested":
                return "reservationStatusRequested";
            case "accepted":
                return "reservationStatusAccepted";
            case "rejected":
                return "reservationStatusRejected";
            case "cancelled":
                return "reservationStatusRejected";
            default:
                return "reservationStatusRequested";
        }
    };

    const isActionButtonDisabled = (reservationStatusCode) => {
        switch (reservationStatusCode) {
            case "requested":
                return false;
            case "accepted":
                return true;
            case "rejected":
                return true;
            case "cancelled":
                return true;
            default:
                return true;
        }
    };

    const isRejectButtonDisabled = (reservationStatusCode) => {
        return reservationStatusCode === "rejected" || reservationStatusCode === "cancelled";
    };

    const getRejectButtonText = (reservationStatusCode) => {
        switch (reservationStatusCode) {
            case "requested":
                return "Odbij";
            case "accepted":
                return "Otkaži";
            case "rejected":
                return "Otkazan";
            case "cancelled":
                return "Otkazan";
            default:
                return true;
        }
    };

    const isRowGrayedOut = (reservationStatusCode) => {
        switch (reservationStatusCode) {
            case "requested":
                return false;
            case "accepted":
                return true;
            case "rejected":
                return true;
            case "cancelled":
                return true;
            default:
                return true;
        }
    };

    const getNumberOfBookingsPerTurf = (turfId) => {
        if (numberOfBookingsPerTurf && turfId in numberOfBookingsPerTurf) {
            return numberOfBookingsPerTurf[turfId];
        }

        return null;
    };

    const handleCloseModal = useCallback(() => {
        closeBookingModal();
    }, [closeBookingModal]);

    const handleAddBookingSubmit = async (bookingDetails) => {
        if (bookingDetails.recurringDays && bookingDetails.recurringDays.length > 0) {
            const bookingResult = await addRecurringBooking(court, selectedTurf, bookingDetails, bookings);
            await fetchBookingsForCourt();

            return bookingResult;
        } else {
            const bookingResult = await addBooking(
                bookingDetails.date,
                bookingDetails.startTime,
                bookingDetails.endTime,
                bookingDetails.customUser,
                bookingDetails.autoAccept
            );
            await fetchBookingsForCourt();
            return bookingResult;
        }
    };

    const handleBookingAccept = async (e) => {
        const bookingId = e.target.getAttribute("data-bookingid");
        const result = await acceptBooking(bookingId);

        if (result && result.success) {
            await fetchBookingsForCourt();
        } else {
            setErrorMessage(result.errorMessage);
        }
    };

    const openRejectModal = async (e) => {
        setRejectModalOpen(true);
        const bookingId = e.target.getAttribute("data-bookingid");
        setBookingIdToReject(bookingId);
    };

    const handleBookingReject = async () => {
        const result = await rejectBooking(bookingIdToReject);
        if (result && result.success) {
            await fetchBookingsForCourt();
        } else {
            setErrorMessage(result.errorMessage);
        }

        closeRejectModal();
    };

    const closeRejectModal = () => {
        setRejectModalOpen(false);
        setBookingIdToReject(null);
    };

    const handleCloseErrorMessage = () => setErrorMessage(null);

    const handleShowRecurringBookings = () => setShowRecurringBookings((prev) => !prev);

    const formatRecurringSchedule = (days) => {
        if (!days || days.length === 0) return "";

        if (days.length === 7) return "Svaki dan";

        const scheduleArray = [];

        days.forEach((d) => {
            switch (d) {
                case "Mon":
                    scheduleArray.push("Pon");
                    break;
                case "Tue":
                    scheduleArray.push("Uto");
                    break;
                case "Wed":
                    scheduleArray.push("Sre");
                    break;
                case "Thu":
                    scheduleArray.push("Čet");
                    break;
                case "Fri":
                    scheduleArray.push("Pet");
                    break;
                case "Sat":
                    scheduleArray.push("Sub");
                    break;
                case "Sun":
                    scheduleArray.push("Ned");
                    break;
                default:
                    break;
            }
        });

        return scheduleArray.join(", ");
    };

    const handleRemoveRecurringBooking = async (e) => {
        const bookingId = e.target.value;
        await removeRecurringBooking(court, selectedTurf, bookingId);
        await fetchBookingsForCourt();
        court.recurring_bookings = court.recurring_bookings?.filter((t) => t.id !== bookingId);
    };

    const openSplitModal = (e) => {
        const bookingId = e.target.getAttribute("data-bookingid");
        setBookingIdToSplit(bookingId);
        setSplitModalOpen(true);
    };

    const closeSplitModal = (e) => {
        setSplitModalOpen(false);
    };

    const handleSplitBooking = async (bookingDetails) => {
        const result = await splitBooking(bookingIdToSplit, bookingDetails.startTime, bookingDetails.endTime);
        await fetchBookingsForCourt();
        return result;
    };

    return (
        <div className="mycourt-courtCard">
            <div className="mycourt-title">{court?.name}</div>
            <div className="mycourt-ratingAndAddress">
                <div className="courtRating">
                    <StarRating rating={court?.rating} />
                    <div className="ratingNumber">{court?.rating}</div>
                </div>
                <div className="mycourt-address">{court?.address}</div>
            </div>
            <div className="mycourt-turfs-container">
                <div className="mycourt-turfs">
                    {court?.court_turfs.map((turf, pageIndex) => (
                        <div
                            className="mycourt-turfBtn"
                            style={{
                                opacity: turf?.id === selectedTurf ? "1" : "0.3",
                            }}
                            data-turf={turf?.id}
                            data-court={court?.id}
                            key={turf?.id}
                            onClick={handleTurfChange}
                        >
                            {<div className="mycourt-turfBtn-name">{turf?.name}</div>}
                            {getNumberOfBookingsPerTurf(turf?.id) > 0 && (
                                <div className="mycourt-turfBtn-number">({getNumberOfBookingsPerTurf(turf?.id)})</div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="mycourt-addBooking">
                    <button onClick={handleShowRecurringBookings}>
                        <AutorenewIcon className="mycourt-plusicon" />
                        <span className="mycourt-addBooking-text">Stalni termini</span>
                    </button>
                    <button onClick={openBookingModal}>
                        <AddCircleOutlineIcon className="mycourt-plusicon" />
                        <span className="mycourt-addBooking-text">Dodaj Termin</span>
                    </button>
                </div>
            </div>
            {errorMessage && (
                <div className="mycourt-error">
                    {errorMessage}
                    <button className="mycourt-error-btn" onClick={handleCloseErrorMessage}>
                        Zatvori
                    </button>
                </div>
            )}
            {showRecurringBookings && (
                <div className="mycourt-recurringBookings">
                    <div className="mycourt-recurringBookings-title">Stalni termini</div>
                    {!recurringBookings || recurringBookings.length === 0 ? (
                        <span>Trenutno nema stalnih termina</span>
                    ) : null}
                    {recurringBookings &&
                        recurringBookings.map((rb) => (
                            <div className="mycourt-bookingRow" key={rb.id}>
                                <div
                                    className={`mycourt-bookingRow-timeSlot ${getReservationStatusClass(
                                        rb.reservationStatus
                                    )}`}
                                >
                                    {formatTime(rb.start_hour, rb.start_minute, rb.end_hour, rb.end_minute)}
                                </div>
                                <div
                                    className={`mycourts-reservationStatus ${getReservationStatusClass(
                                        rb.reservationStatus
                                    )}`}
                                >
                                    {formatRecurringSchedule(rb.days)}
                                </div>
                                <div
                                    className={`mycourt-bookingRow-requestedBy ${getReservationStatusClass(
                                        rb.reservationStatus
                                    )}`}
                                >
                                    {rb.reservation_requested_by}
                                </div>
                                <div className="mycourt-bookingRow-buttons">
                                    <button
                                        className="mycourt-bookingRow-buttons-remove"
                                        data-bookingid={rb.id}
                                        value={rb.id}
                                        onClick={handleRemoveRecurringBooking}
                                    >
                                        Ukloni
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            )}
            <div className="mycourt-bookingsContainer">
                {formattedBookingsByDay?.allDates?.map((date, pageIndex) => (
                    <div className="mycourt-booking" key={pageIndex}>
                        <div className="mycourt-bookingDate">{formatDate(date)}</div>
                        {formattedBookingsByDay?.bookingsByDay[date]?.map((booking, bookingKey) => (
                            <div className="mycourt-bookingCard" key={bookingKey}>
                                <div className="mycourt-bookingRow">
                                    <div
                                        onClick={openSplitModal}
                                        data-bookingid={booking.id}
                                        style={{
                                            opacity:
                                                booking.reservationStatus !== "accepted" &&
                                                booking.reservationStatus !== "requested"
                                                    ? "0.4"
                                                    : "1",
                                        }}
                                        className={`mycourt-bookingRow-timeSlot ${getReservationStatusClass(
                                            booking.reservationStatus
                                        )}`}
                                    >
                                        {formatTime(
                                            booking.startHour,
                                            booking.startMinute,
                                            booking.endHour,
                                            booking.endMinute
                                        )}
                                    </div>
                                    <div
                                        style={{ opacity: isRowGrayedOut(booking.reservationStatus) ? "0.4" : "1" }}
                                        className={`mycourts-reservationStatus ${getReservationStatusClass(
                                            booking.reservationStatus
                                        )}`}
                                    >
                                        {getReservationStatusText(booking.reservationStatus)}
                                    </div>
                                    <div
                                        style={{ opacity: isRowGrayedOut(booking.reservationStatus) ? "0.4" : "1" }}
                                        className={`mycourt-bookingRow-requestedBy ${getReservationStatusClass(
                                            booking.reservationStatus
                                        )}`}
                                    >
                                        {booking.reservationRequestedBy}
                                    </div>
                                    <div className="mycourt-bookingRow-buttons">
                                        <button
                                            disabled={isRejectButtonDisabled(booking.reservationStatus)}
                                            className="mycourt-bookingRow-buttons-reject"
                                            onClick={openRejectModal}
                                            data-bookingid={booking.id}
                                        >
                                            {getRejectButtonText(booking.reservationStatus)}
                                        </button>
                                        <button
                                            disabled={isActionButtonDisabled(booking.reservationStatus)}
                                            className="mycourt-bookingRow-buttons-accept"
                                            onClick={handleBookingAccept}
                                            data-bookingid={booking.id}
                                        >
                                            Prihvati
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <BookingModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSubmit={handleAddBookingSubmit}
                adminView={true}
            />
            <ConfirmationModal
                isOpen={isRejectModalOpen}
                onClose={closeRejectModal}
                onSubmit={handleBookingReject}
                message={"Da li ste sigurni da želite da otkažete ovaj termin?"}
            />
            <BookingModal
                isOpen={isSplitModalOpen}
                onClose={closeSplitModal}
                onSubmit={handleSplitBooking}
                modalText={"Napravite slobodan termin u izabranom zauzetom terminu"}
            />
        </div>
    );
};

export default MyCourt;
