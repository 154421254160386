import React from "react";
import FilterGroup from "./FilterGroup";
import { useState, useEffect } from "react";
import { priceRanges, courtTypes, additionalContent } from "../../utils/constants";

const Filters = ({ onApplyFilters }) => {
    const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
    const [selectedAddtionalContent, setSelectedAdditionalContent] = useState([]);
    const [selectedCourtTypes, setSelectedCourtTypes] = useState([]);

    useEffect(() => {
        onApplyFilters({
            priceRanges: selectedPriceRanges,
            additionalContent: selectedAddtionalContent,
            courtTypes: selectedCourtTypes,
        });
    }, [selectedPriceRanges, selectedAddtionalContent, selectedCourtTypes, onApplyFilters]);

    const handlePriceRangeChange = (range) => {
        const newFilters = selectedPriceRanges.includes(range)
            ? selectedPriceRanges.filter((r) => r !== range)
            : [...selectedPriceRanges, range];
        setSelectedPriceRanges(newFilters);
    };

    const handleAdditionalContentChange = (content) => {
        const newContent = selectedAddtionalContent.includes(content)
            ? selectedAddtionalContent.filter((c) => c !== content)
            : [...selectedAddtionalContent, content];
        setSelectedAdditionalContent(newContent);
    };

    const handleSelectedCourtTypes = (type) => {
        const newTypes = selectedCourtTypes.includes(type)
            ? selectedCourtTypes.filter((t) => t !== type)
            : [...selectedCourtTypes, type];
        setSelectedCourtTypes(newTypes);
    };

    return (
        <div style={styles.container}>
            <div style={styles.filters}>
                <FilterGroup
                    title={"Cena"}
                    content={priceRanges}
                    checked={selectedPriceRanges}
                    handleChecked={handlePriceRangeChange}
                />
                <FilterGroup
                    title={"Vrsta podloge"}
                    content={courtTypes}
                    checked={selectedCourtTypes}
                    handleChecked={handleSelectedCourtTypes}
                />
                <FilterGroup
                    title={"Dodatni sadržaji"}
                    content={additionalContent}
                    checked={selectedAddtionalContent}
                    handleChecked={handleAdditionalContentChange}
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        marginBottom: "16px",
    },
    filters: {},
    filterHeading: {
        color: "black",
    },
    filterGroup: {
        paddingBottom: "25px",
        color: "gray",
        fontSize: "14px",
        borderBottom: "1px solid lightgray",
    },
    input: {
        marginTop: "2px",
    },
    filter: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        marginBottom: "8px",
    },
};

export default Filters;
