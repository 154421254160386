import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

const DropdownIndicator = () => null;

const ServersideFilter = ({
    fetchFuction,
    icon,
    placeholderText,
    onOptionSelected,
    disabled = false,
    externalDependencies,
}) => {
    const Icon = icon;

    const CustomPlaceholder = (props) => (
        <components.Placeholder {...props}>
            {props.hasValue || props.selectProps.menuIsOpen ? null : (
                <div style={styles.container}>
                    <Icon />
                    <div style={styles.placeholderText}>{placeholderText}</div>
                </div>
            )}
        </components.Placeholder>
    );

    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const loadOptions = async () => {
            if (fetchFuction) {
                const fetchFuncArgs = externalDependencies ? { ...externalDependencies } : {};
                const result = await fetchFuction(fetchFuncArgs);

                if (result) {
                    const options = result.map((result) => ({ value: result.name, label: result.name }));
                    setOptions(options);
                }
            }
        };

        loadOptions();
    }, [fetchFuction, externalDependencies]);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onOptionSelected(selectedOption ? selectedOption.value : null);
    };

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isDisabled={disabled}
            isClearable
            components={{
                Placeholder: CustomPlaceholder,
                DropdownIndicator,
            }}
        />
    );
};

const styles = {
    container: {
        display: "flex",
        gap: "5px",
    },
    placeholderText: {
        marginTop: "3px",
    },
};

export default ServersideFilter;
