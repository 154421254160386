import React, { useState, useEffect, useCallback } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./main.css";

const ImageCarousel = ({ images }) => {
    const interval = 3000;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const goToPrevious = () => {
        if (!isTransitioning) {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
                setIsTransitioning(false);
            }, 500); // Duration of the transition
        }
    };

    const goToNext = useCallback(() => {
        if (!isTransitioning) {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
                setIsTransitioning(false);
            }, 500);
        }
    }, [images.length, isTransitioning]);

    useEffect(() => {
        const autoSwitch = setInterval(() => {
            goToNext();
        }, interval);

        return () => clearInterval(autoSwitch);
    }, [currentIndex, interval, goToNext]);

    return (
        <div className="carouselContainer">
            <button className="arrowButtonLeft" onClick={goToPrevious}>
                <ArrowBackIosIcon />
            </button>
            <div className="carousel">
                <img
                    src={images[currentIndex]}
                    alt={`Slide ${currentIndex}`}
                    className="imageCarousel-image"
                    style={{ opacity: isTransitioning ? 0.1 : 1 }}
                />
            </div>
            <button className="arrowButtonRight" onClick={goToNext}>
                <ArrowForwardIosIcon />
            </button>
            <div className="dotsContainer">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className="dot"
                        style={{
                            backgroundColor: currentIndex === index ? "gray" : "black",
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel;
