import React from "react";
import { Modal, Box } from "@mui/material";
import "./main.css";

const ConfirmationModal = ({ isOpen, onClose, message, onSubmit }) => {
    const handleSubmit = async () => {
        onSubmit();
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={"confirmationModal-modalContent"}>
                <div className="confirmationModal-message">{message}</div>
                <div className="confirmationModal-buttons">
                    <button className="confirmationModal-cancel" onClick={onClose}>
                        Ne
                    </button>
                    <button className="confirmationModal-confirm" onClick={handleSubmit}>
                        Da
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
