import { db } from "../firebaseConfig";
import { collection, getDocs, where, query } from "firebase/firestore";

export const fetchCourtNames = async ({ city }) => {
    const courtNamesRef = collection(db, "court_names");
    let q = query(courtNamesRef, where("city", "==", city));
    const snapshot = await getDocs(q);
    const courtNames = new Set();
    snapshot.docs.forEach((doc) => {
        const courtName = doc.data();
        if (courtName) {
            courtNames.add({ name: courtName.court_name });
        }
    });

    return Array.from(courtNames);
};
