import { create } from "zustand";

const courtInfoReducer = (state, action) => {
    switch (action.type) {
        case "setNumberOfBookingsPerTurf": {
            return { numberOfBookingsPerTurf: action.bookings };
        }
        default:
            return state;
    }
};

const useCourtInfo = create((set) => ({
    numberOfBookingsPerTurf: null,
    setNumberOfBookingsPerTurf: (bookings) =>
        set((state) => courtInfoReducer(state, { type: "setNumberOfBookingsPerTurf", bookings })),
}));

export default useCourtInfo;
